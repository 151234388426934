/******************************************************************************************
 * LEGACY UTM CODE MAP
 * ------------------------------------------------------------------------------
 * This is a map of legacy UTM codes to their corresponding clinic codes.
 * This map can be edited to capture any UTM codes that may be found in the links
 * created by the clinics or their marketing teams.
 * 
 * The goal is to capture those UTM codes and map them to the correct clinic codes
 * which are passed as referral codes to the getLineItems query which is managed by
 * the loadLineItems utitlity.
 * 
 * * PASHA NOTE:
 * To make this easy, when I'm gone... you can search the code base by PASHA in order
 * to quickly find the nonsense that I wrote specifically for this project for you.
 * 
 * ***************************************************************************************/
export const legacyUTMCodes: Record<string, string> = {
    "ivf-florida": "0_ivffl",
    "luminary-ivffl": "0_ivffl",
    "ivffl": "0_ivffl",
    "rmany": "0_rmany",
    "luminary-fci": "0_fci",
    "fci": "0_fci",
    "fcionline.com": "0_fci",
    "luminary-rsc": "0_rsc",
    "rsc": "0_rsc",
    "luminary-sgf": "0_sgf",
    "sgf": "0_sgf",
    "luminary-sva": "0_sgf",
    "luminary-dmv": "0_sgf",
    "sva": "0_sgf",
    "dmv": "0_sgf",
    "shadygrovefertility.com": "0_sgf",    
  };

  export default legacyUTMCodes;