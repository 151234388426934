/********************************************************************************
 * UTIL: LOAD LINE ITEMS
 * ------------------------------------------------------------------------------
 * This utility function is used to load line items from the database into the
 * local state. This function is called when the app is first loaded. It will
 * load all line items from the database and store them in the zustand store.
 *
 * This function will initailly be called in the gatsby-browser.js file.
 * However, it should also be called whenever there's a need to refresh the
 * line items in the app. This could be if, for example, the data is somehow
 * lost and needs to be reloaded.
 * *******************************************************************************/

import { generateClient } from 'aws-amplify/api';
import { getLineItems } from 'src/graphql/queries';
import { GetLineItemsQuery } from 'src/API';
import { useStoreV2 } from 'src/store/StoreV2';
import posthog from 'posthog-js';

/********************************************************************************
 * IS AUTHENTICATED
 * ------------------------------------------------------------------------------
 * Import the isAuthenticated function from the ELO package to determine the
 * authentication mode that is necessary to use. The isAuthenticated function
 * simplifies the process of determining the authentication mode by checking the
 * state of the user.
 * *****************************************************************************/
import { isAuthenticated } from 'src/elo';

interface LoadLineItemsProps {
  id?: string;
  affiliation?: string;
  referral_code?: string;
}

export const loadLineItems = async ({ id, affiliation, referral_code }: LoadLineItemsProps) => {
  const client = generateClient();

  const tcs = useStoreV2.getState().tcs;
  const updateStore = useStoreV2.getState().updateState;
  const updateTCS = useStoreV2.getState().updateTCS;

  /********************************************************************************
   * VERIFY AFFILIATION OR REFERRAL CODE
   * ------------------------------------------------------------------------------
   * We need to check if an affiliation or referral code has..
   *
   * 1. Been provided
   * This would be passed in by gatsby-browser when the app loads. Gatsby-Browser
   * will get and pass the querystring parameters to the loadLineItems function.
   * If this is the case, then we need to use the affiliation or referral code to
   * load the line items from the database.
   *
   * 2. Not been provided then check if something has already been set.
   * There is a case where the querystring parameters haven't been provided, but
   * we have previously set the affiliation or referral code. This can be checked
   * by inspecting the lineItems in the store to see if they exist and if there
   * is a valid affiliation or referral code.
   *
   * 3. Not been provided and nothing has been set.
   * If neither of the above are true, then we need to load the line items from
   * the database by passing null values to those params.
   * *******************************************************************************/

  /********************************************************************************
   * 1. Start by setting the aff and ref code based on any avaialble querystring
   * data.
   * *****************************************************************************/
  let aff_code = affiliation?.toLowerCase();
  let ref_code = referral_code?.toLowerCase();
  
  /********************************************************************************
   * 2. If both of those values are null, then check the store to see if we have
   * any values in the tcs object in the store and set them to that.
   * *****************************************************************************/  
  if (!aff_code && !ref_code) {
    aff_code = tcs?.affiliation ?? undefined;
    ref_code = tcs?.referral_code ?? undefined;
  }
  
  /********************************************************************************
   * 3. At this point, we will have an appropriate value for the aff and ref code
   * that we can pass to getLineItems.
   * 
   * * NOTE : IMPORTANT : FUTURE SELF : OTHER DEVS
   * In the code below, we pass the ref values to the getLineItems query. BUT...
   * we only set the persistent values in the tcs object in the store if a valid
   * value is returned from the query.
   * *****************************************************************************/  


  try {
    updateStore(state => {
      state.loadingLineItems = true;
    });

    /********************************************************************************
     * CONDITIONAL AUTH MODE
     * ------------------------------------------------------------------------------
     * Set the auth mode approriately based on the user's authentication status.
     * This is necessary because the user's authentication status will determine
     * how the data is fetched from the database. If this is not done, then the
     * query will fail due to a permissions error.
     * *******************************************************************************/
    const authMode = isAuthenticated() ? 'userPool' : ('iam' as 'userPool' | 'iam');

    /********************************************************************************
     * INPUT PARAMETERS
     * ------------------------------------------------------------------------------
     * The input params will be constructed based on any querystring parameters
     * that have been passed to the page.
     * *******************************************************************************/

    // console.log('affiliation', aff_code);
    // console.log('referral_code', ref_code);

    const response = (await client.graphql({
      query: getLineItems,
      authMode,
      variables: {
        input: {
          anonymousId: id ?? posthog.get_distinct_id(),
          affiliation: aff_code,
          referralCode: ref_code,                    
        },
      },
    })) as { data: GetLineItemsQuery };

    const lineItems = response.data;

    updateStore(state => {
      state.lineItems = lineItems;
      state.loadingLineItems = false;
    });

    
    

    /********************************************************************************
     * UPDATE THE TCS OBJECT IN THE STORE
     * ------------------------------------------------------------------------------
     * This will update the tcs object in the store with the affiliation and referral
     * code that have been fetched from the database. We set this here using the
     * values returned from the database because the getLineItems query actually
     * validates the codes and ensures that they are valid. Therefore, we can be
     * confident that these are the correct values to use.
     *
     * If we just used the values from the querystring parameters, we could end up
     * with an invalid value that doesn't actually exist in the database.
     * 
     * The values are set based on the following logic:
     * 1. If the value exists in the response from the database, use that.
     * 2. If the value does not exist in the response from the database, but it does
     * exist in the tcs object in the store, use that.
     * 3. If the value does not exist in the response from the database and it does
     * not exist in the tcs object in the store, set it to undefined.
     * 
     * This ensures that the most recent valid value is used to populate the tcs
     * object in the store.
     * *******************************************************************************/

    // Calculate the max discount based on the line items
    // 1. Create an array of all the discount values in lineItems
    const discountValues: number[] | undefined = lineItems?.getLineItems.map(item => item.discount ?? 0) ?? undefined;
    // 2. Reduce the array to find the maximum discount value
    const maxDiscount = Math.max(...discountValues);

    updateTCS(state => {
      state.affiliation = lineItems?.getLineItems[0]?.affiliation ?? tcs?.affiliation ?? undefined;
      state.referral_code = lineItems?.getLineItems[0]?.referralCode ?? tcs?.referral_code ?? undefined;
      state.max_discount = maxDiscount || 15; // Default max discount to 15
    });

    return lineItems;
  } catch (error) {
    console.error('Error fetching line items', error);
    updateStore(state => {
      state.loadingLineItems = false;
    });

    console.error('Error fetching line items', error);
  }
};

export default loadLineItems;
