import * as React from 'react';
import * as Sentry from '@sentry/gatsby';
import type { GatsbyBrowser } from 'gatsby';
import { Provider as EloStoreProvider } from './src/features/store/EloStore.Provider';
import PostHog from './src/PostHog';
import posthog from 'posthog-js';
import { Amplify } from 'aws-amplify';
import awsconfig from './src/aws-exports';
import { loadLineItems } from './src/utils/loadLineItems';
import { Script } from 'gatsby';

import legacyUTMCodes from './src/data/legacyUTMCodes';

const sentryDsn =
  process.env.GATSBY_SENTRY_DSN ??
  'https://8b98b66e41494b2c9f8f09cf5ada7a99@o1011055.ingest.us.sentry.io/4508205342916608';
const sentryEnv = process.env.GATSBY_SENTRY_ENVIRONMENT ?? 'local';

/********************************************************************************
 * ON CLIENT ENTRY
 * ------------------------------------------------------------------------------
 * This function is called when the Gatsby browser runtime first starts. It is
 * a good place and time to load any scripts that are needed for the site or to
 * perform any other client-side initialization such as loading data into the
 * store.
 * ******************************************************************************/
export const onClientEntry = async () => {
  // Initizlise the TrueMed Prequal Widget Script
  const script = document.createElement('script');
  script.src =
    'https://truemed-public.s3.us-west-1.amazonaws.com/truemed-ads/prequal-widget.min.js';
  script.defer = true;
  document.body.appendChild(script);

  // Initialize the Attentive Script
  const attentiveScript = document.createElement('script');
  attentiveScript.src = 'https://cdn.attn.tv/luminaryvitamins/dtag.js';
  attentiveScript.defer = true;
  document.body.appendChild(attentiveScript);

  // Initialize Sentry
  Sentry.init({
    dsn: sentryDsn,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: sentryEnv,
  });
};

/********************************************************************************
 * ON INITIAL CLIENT RENDER
 * ------------------------------------------------------------------------------
 * This function is called when the Gatsby browser runtime first renders the
 * client. It is a good place and time to perform any client-side initialization
 * such as resetting the scroll position.
 * ******************************************************************************/

export const onInitialClientRender: GatsbyBrowser['onInitialClientRender'] = () => {
  /**********************************************************************
   * EXECUTE LOAD LINE ITEMS
   * --------------------------------------------------------------------
   * Capture the referral code and the affiliation code that will be
   * used to determine the values passed to getLineItems which will
   * then return the correct prices and other conditional data.
   *
   * Affiliation should be set based on the keys 'affiliation' or 'aff'
   * and give deference to 'aff' over 'affiliation'.
   *
   * NOTE:
   * This action was moved from onClientEntry to here because
   * the onClientEntry lifecycle method is called before posthog is ready.
   * By calling it here, we can be sure that posthog is ready to return
   * the distinct_id.
   **********************************************************************/
  const searchParams = new URLSearchParams(window.location.search);

  /********************************************************************************
   * GET AFFILIATION CODE
   * ------------------------------------------------------------------------------
   * The affiliation code comes from the query string with priority on 'aff' over
   * 'affiliation' followed by UTM codes prioritized in the order:
   * campaign → content → term. Then the UTM codes need to be mapped to the
   * proper affiliation code.
   ******************************************************************************/


  const utmCodeUsed = (
    searchParams.get('utm_campaign') ||
    searchParams.get('utm_content') ||
    searchParams.get('utm_term')
  )?.toLowerCase();

  const getLegacyAffiliationCode = (utm: string): string | undefined => {
    return legacyUTMCodes[utm];
  };

  const legacyAffiliationCode = getLegacyAffiliationCode(utmCodeUsed ?? 'noop');

  const affiliation = searchParams.get('aff') || searchParams.get('affiliation') || legacyAffiliationCode;
  const normalizedAffiliation = affiliation?.toLowerCase();

  /********************************************************************************
   * GET REFERRAL CODE
   * ------------------------------------------------------------------------------
   * The referral code comes from 'referral_code' first, followed by UTM codes
   * prioritized in the order: campaign → content → term.
   ******************************************************************************/
  const refCodeUsed = (
    searchParams.get('referral_code') ||
    searchParams.get('utm_campaign') ||
    searchParams.get('utm_content') ||
    searchParams.get('utm_term')
  )?.toLowerCase();

  /**********************************************************************
   * LOAD LINE ITEMS
   * --------------------------------------------------------------------
   * Ensure posthog is ready before proceeding. Then, execute
   * getLineItems query and load the returned data into the global store.
   **********************************************************************/
  const distinctId = posthog?.get_distinct_id();
  if (!distinctId) {
    console.warn('PostHog distinct_id is not available yet.');
    return;
  }

  loadLineItems({
    id: distinctId,
    affiliation: normalizedAffiliation ?? undefined,
    referral_code: refCodeUsed ?? undefined,
  });

  // Ensure the page starts at the top
  window.scrollTo(0, 0);
};


export const onPreRouteUpdate: GatsbyBrowser['onPreRouteUpdate'] = () => {
  document.body.scrollTop = 0;
};

/********************************************************************************
 * ON ROUTE UPDATE
 * ------------------------------------------------------------------------------
 * This function is called when the route is updated. It is a good place to perform
 * any actions that need to occur when the route changes. For example, you can use
 * this function to track page views in an analytics tool.
 * ******************************************************************************/
// Strip query strings from canonical urls

// Interrupt the route update to capture the page view for posthog on the inital load
let hasRouteChanged = false;

export const onRouteUpdate = props => {
  // Get the location data from the props
  const { location } = props;
  const elem = document.querySelector(`link[rel='canonical']`);
  if (elem) {
    const hasHref = elem.getAttribute(`href`) ?? false;
    const protocol = elem.getAttribute(`data-baseProtocol`);
    const host = elem.getAttribute(`data-baseHost`);
    if (hasHref && protocol && host) {
      // `${protocol}//${host}${location.pathname}${location.search}${location.hash}`
      elem.setAttribute(`href`, `${protocol}//${host}${location.pathname}${location.hash}`);
    }
  }

  // Capture the page view for posthog only if route has changed
  if (hasRouteChanged) {
    // Only capture the page view if the route has changed
    // Will not capture the first page view on initial load
    // If a user goes directly to a page, the first page view will not be captured manually but it will be captures via the posthog autotrack
    posthog.capture('$pageview', {
      current_url: location.href,
    });
  } else {
    // Initially false, this will bypass the first page view capture
    // Set to true so that subsequent page views are captured
    hasRouteChanged = true;
  }
};

// Amplify configuration
const awsExportOverloads = {
  ...(awsconfig ?? {}),
  aws_appsync_authenticationType: 'AWS_IAM',
};
Amplify.configure(awsExportOverloads);

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => (
  <EloStoreProvider>
    <PostHog>
      {element}
      <ConditionalChatbotScript />
    </PostHog>
  </EloStoreProvider>
);

const ConditionalChatbotScript = () => {
  return (
    <>
      {posthog.getFeatureFlag('pdp-chat-bot') === 'test' ? (
        <Script
          id="ze-snippet"
          src="https://static.zdassets.com/ekr/snippet.js?key=2f954903-367d-4300-a237-dd3ec52c010f"
        />
      ) : null}
    </>
  );
};
